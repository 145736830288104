// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataReport: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_REPORT(state, payload) {
      state.respDataReport = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        if (state.respDataReport) {
          if (state.respDataReport.data.length > 0) {
            const index = state.respDataReport.data.findIndex(p => p.key === payload.value)
            if (index > -1) {
              state.respDataReport.data.splice(index, 1)
            }
          }
        }
        state.respData.data.unshift(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getLanguage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/servicesConfig/getLanguage?id=${item.id}&pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getReport({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/servicesConfig/getReport?id=${item.id}`)
          .then(response => {
            commit('GET_REPORT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addLanguage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/servicesConfig/addLanguage', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    deleteLanguage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/root/servicesConfig/deleteLanguage?id=${item._id}&servicesId=${item.servicesId}`)
          .then(response => {
            commit('REMOVE', item._id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateLanguage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/servicesConfig/updateLanguage', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateToPublic({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/servicesConfig/updateToPublic', item)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
