<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Value')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Value"
                  vid="value"
                  rules="required"
                >
                  <b-form-input
                    id="register-value"
                    v-model="value"
                    name="register-value"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Value')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-for="(item , index) in language"
              :key="index"
              cols="12"
            >
              <!-- firstName -->
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name"
                  :vid="item.name"
                >
                  <b-form-input
                    v-if="langTranslate[index]"
                    :id="item.name"
                    :name="item.name"
                    :value="langTranslate[index]['value']"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t(item.name)"
                    @input="inputLanguage($event,item.lang ,index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  // BFormFile,
  // BMedia,
  // BMediaAside,
  // BMediaBody,
  // BLink,
  // BImg,
  // BCardText,
  // BInputGroupAppend,
  // BInputGroup,
  BFormInput, BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BSidebar,
    BButton,
    BForm,
    // BFormFile,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    // BImg,
    // BCardText,
    BFormInput,
    BFormGroup,
    // BInputGroupAppend,
    // BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      value: '',
      langTranslate: [],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    respDataCountry() {
      return store.state[this.storeModuleName].respDataCountry != null ? store.state[this.storeModuleName].respDataCountry.data : []
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.langTranslate.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.langTranslate.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.langTranslate.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else if (Object.entries(this.data).length === 1) {
          this.$refs.formData.reset()
          this.initValues()
          const {
            value,
          } = this.data
          this.value = value

          if (this.langTranslate.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.langTranslate.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.langTranslate.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id, value, langTranslate,
          } = this.data
          this.dataId = _id
          this.value = value
          this.langTranslate = langTranslate

          if (this.langTranslate.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.langTranslate.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.langTranslate.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.langTranslate.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.langTranslate[index].lang)
              if (indexLanguage < 0) {
                this.langTranslate.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  methods: {
    inputLanguage(input, lang, index) {
      console.log(input, lang, index)
      if (this.langTranslate[index].lang === lang) {
        this.langTranslate[index].value = input
      }
      console.log(this.langTranslate)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.logo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.value = ''
      this.langTranslate = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            value: this.value,
            langTranslate: this.langTranslate,
            servicesId: this.$route.params.id,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/updateLanguage`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$emit('update:show', false)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/addLanguage`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$emit('update:show', false)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
